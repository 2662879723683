const types = [
  { value: 'bool', title: 'True / False' },
  { value: 'rating', title: 'Rating' },
  { value: 'text', title: 'Text' },
  { value: 'statement', title: 'Statement' },
]

export const complianceCalculationFixedNumber = 15

export default { types }

export const colors = {
  // green: '#50b692', // old color
  green: '#4e8769', // New

  // primary: '#50b692', // old color
  primary: '#56616d', // New
}
