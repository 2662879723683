<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <habit-logo />
      <h2 class="brand-text text-primary ml-1">
        Habit
      </h2>
    </b-link>

    <!-- Loading -->
    <loading
      v-if="isLoading || isSubmitting"
      :active="true" 
      :is-full-page="true"
      :color="colors.primary"
    />

    <div v-else class="misc-inner p-2 p-sm-3 mt-5">
      <div class="w-100 text-center">

        <h3 v-if="locationNotFound" class="text-secondary">
          {{ $t('location_not_found_check_url') }}
        </h3>
      
        <div v-else>
          
          <h5 class="mb-2 mb-sm-5">
            <u>{{ $t('label.Location') }}</u>: <b>{{ location }}</b>
          </h5>
          <!-- Title -->
          <h2 class="mb-sm-3">
            {{ isSubmitted ? $t('thank_you_submitting_opinion') : $t('leave_us_your_opinion') }}
          </h2>

          <div v-if="isSubmitted">
            <!-- Disabled Opinion -->
            <b-row v-if="opinion" class="mt-1">
              <b-col cols="12">
                <b-form-textarea
                  v-model="opinion"
                  rows="3"
                  :disabled="true"
                />
              </b-col>
            </b-row>
            
            <!-- Disabled State -->
            <b-row>
              <b-col cols="12">
                <feather-icon :icon="iconNames[feedback]" :size="iconSize" :class="`my-3 my-sm-2 text-${variants[feedback]}`"></feather-icon>
              </b-col>
            </b-row>
          </div>

          <div v-else>
            <!-- Opinion -->
            <b-row class="mt-1">
              <b-col cols="12">
                <b-form-group :label="$t('message.anonymous_opinion')">
                  <b-form-textarea
                    v-model="opinion"
                    rows="3"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <span >
              {{ $t('message.rateYourOpinion') }}
            </span>

            <!-- State -->
            <b-row>
              <!-- Happy -->
              <b-col sm="4">
                <b-button class="mx-2 my-1" variant="outline-success" :pressed="feedback === 'positive'" @click="feedback = 'positive'">
                  <feather-icon icon="SmileIcon" :size="iconSize" class="text-success"></feather-icon>
                </b-button>
              </b-col>
              <!-- neutral -->
              <b-col sm="4">
                <b-button class="mx-2 my-sm-1" variant="outline-warning" :pressed="feedback === 'neutral'" @click="feedback = 'neutral'">
                  <feather-icon icon="MehIcon" :size="iconSize" class="text-warning"></feather-icon>
                </b-button>
              </b-col>
              <!-- Sad -->
              <b-col sm="4">
                <b-button class="mx-2 my-1" variant="outline-danger" :pressed="feedback === 'negative'" @click="feedback = 'negative'">
                  <feather-icon icon="FrownIcon" :size="iconSize" class="text-danger"></feather-icon>
                </b-button>
              </b-col>
            </b-row>
          </div>         

          <b-button
            v-if="!isSubmitted"
            :variant="(!opinion || !feedback) ? 'outline-secondary' : 'primary'"
            class="mt-1 mt-sm-3 btn-lg"
            :disabled="!opinion|| !feedback || isSubmitting"
            @click="submit"
          >
            {{ $t('message.submit') }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BLink, BButton, BFormTextarea, BFormGroup } from 'bootstrap-vue'
import HabitLogo from '@core/layouts/components/Logo.vue'
import { ref, onMounted } from "@vue/composition-api"
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import useNotifications from "@/composables/useNotifications"
import { useRouter } from '@core/utils/utils'
import i18n from '@/libs/i18n'
import useJwt from '@/auth/jwt/useJwt'
import { colors } from '@/constants'
import realmConnection from '@/views/habit/realm'

export default {
  components: {
    HabitLogo,
    BLink,
    BButton,
    Loading,
    BFormTextarea,
    BFormGroup,
  },
  setup() {
    const { showSuccessMessage, showErrorMessage } = useNotifications()
    const { route } = useRouter()
    const { getItem, createItem, ObjectId } = realmConnection()
    const feedback = ref("")
    const location = ref("")
    const locationNotFound = ref(false)
    const isLoading = ref(true)
    const isSubmitting = ref(false)
    const isSubmitted = ref(false)
    const iconNames = { positive: 'SmileIcon', neutral: 'MehIcon', negative: 'FrownIcon' }
    const variants = { positive: 'success', neutral: 'secondary', negative: 'danger' }
    const widthBreakpoint = 576
    const iconSize = ref("80")
    const opinion = ref("")
    const clientId = ref(null)
    const query = { _id: ObjectId(route.value.params.locationId) }

    onMounted(async () => {
      // Define locale based on the language of the browser UI
      if (window.navigator.language?.startsWith('es')) i18n.locale = 'es'

      // Adjust icon size depending on viewport width
      if (window.innerWidth < widthBreakpoint) iconSize.value = "100"

      try {
        // If user is not logged in (either as a real or anonymous user), then log in as an anonymous user
        if (!useJwt.realm.currentUser) await useJwt.loginAsAnonymousUser()

        // Get location name based on location id from URL param
        const item = await getItem({ collection: 'location', query })
        if (!item) throw new Error('Item not found')

        location.value = item.location
        clientId.value = item.client_id
        if (!location.value) locationNotFound.value = true

      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.location_fetch_error'))
        locationNotFound.value = true
        
      } finally {
        isLoading.value = false
      }
    })

    const submit = async () => {
      isSubmitting.value = true

      const payload = {
        location: ObjectId(route.value.params.locationId),
        date: new Date(),
        feedback: feedback.value,
        detail: opinion.value,
        client_id: clientId.value
      }

      try {
        await createItem({ collection: 'customer_opinion', payload })
        showSuccessMessage(i18n.t('message.answer_success'))
        isSubmitted.value = true
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.answer_submit_error'))
      } finally {
        isSubmitting.value = false
      }
    }

    return {
      feedback,
      location,
      locationNotFound,
      submit,
      isLoading,
      isSubmitting,
      isSubmitted,
      iconNames,
      variants,
      iconSize,
      opinion,
      colors
    };
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>