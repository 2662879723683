import * as Realm from 'realm-web'
import endpoints from '@/libs/endpoints'
import useJwt from '@/auth/jwt/useJwt'

export default function realmConnection() {

  const { BSON: { ObjectId } } = Realm

  // Invoke function
  const invokeFunction = async ({ name, arg }) => {
    return await useJwt.realm.currentUser?.functions[name](arg)
  }

  // Get model
  const getModel = (collection) => {
    const mongodb = useJwt.realm.currentUser?.mongoClient(process.env.VUE_APP_DATA_SOURCE_NAME)
    return mongodb?.db(endpoints.database_name).collection(collection)
  }

  // Get item
  const getItem = async ({ collection, query, options = {} }) => {    // options: projection / sort
    const model = getModel(collection)

    return await model?.findOne(query, options)
  }

  // Get items
  const getItems = async ({ collection, query, options = {} }) => {    // options: projection / sort / limit
    const model = getModel(collection)

    return await model?.find(query, options)
  }

  // Get items with aggregate
  const getItemsWithAggregate = async ({ collection, pipeline }) => {
    const model = getModel(collection)

    return await model?.aggregate(pipeline)
  }

  // Create item
  const createItem = async ({ collection, payload }) => {
    const model = getModel(collection)

    return await model?.insertOne(payload)
  }

  // Create items
  const createItems = async ({ collection, payload }) => {
    const model = getModel(collection)

    return await model?.insertMany(payload)
  }

  // Update item
  const updateItem = async ({ collection, query, action }) => {
    const model = getModel(collection)

    return await model?.updateOne(query, action)
  }

  // Update items
  const updateItems = async ({ collection, query, action }) => {
    const model = getModel(collection)

    return await model?.updateMany(query, action)
  }

  // Delete item
  const deleteItem = async ({ collection, query }) => {
    const model = getModel(collection)

    return await model?.deleteOne(query)
  }

  // Delete items
  const deleteItems = async ({ collection, query }) => {
    const model = getModel(collection)

    return await model?.deleteMany(query)
  }

  // Get items
  const countItems = async ({ collection, query, options = {} }) => {    // options: limit
    const model = getModel(collection)

    return await model?.count(query, options)
  }
  
  return {
    ObjectId,
    invokeFunction,
    getItem,
    getItems,
    getItemsWithAggregate,
    createItem,
    createItems,
    updateItem,
    updateItems,
    deleteItem,
    deleteItems,
    countItems,
  }
}